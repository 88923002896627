import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaCar,
  FaHome,
  FaWrench,
  FaInfoCircle,
  FaMap,
  FaCity,
} from "react-icons/fa";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import loadSetup, { loadCities, submitAppointment } from "../api/main";
import { useActivity } from "./ActivityWrapper";

const AppointmentForm = () => {
  const { loading, setLoading, setMessage } = useActivity();
  // const currentDate = new Date().toISOString().split("T")[0];
  // Get the current date and time
  const currentDate = new Date();
  // Add 45 minutes to the current time
  currentDate.setMinutes(currentDate.getMinutes() + 45);

  const excludedTimes = [
    { startHour: 0, startMinute: 0, endHour: 6, endMinute: 30 }, // Exclude times from 12:00 AM to 6:30 AM
    { startHour: 19, startMinute: 0, endHour: 23, endMinute: 59 }, // Exclude times from 7:00 PM to 11:59 PM
  ];
  const [setupData, setSetupData] = useState({
    services: [],
    vehicle_types: [],
    states: [],
    cities: [],
    helpMessages: {},
  });

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    address: "",
    appointment_datetime: currentDate,
    vehicle_types: "",
    services: "",
    additional_info: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDatetimeChange = (value) => {
    // React-datetime passes the selected date/time directly to the event handler
    // You can directly set it to the state
    setFormData((prevState) => ({
      ...prevState,
      appointment_datetime: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const state_ = setupData.states.find(
        (state) => state.id === parseInt(formData.state, 10)
      );
      const city_ = setupData.cities.find(
        (city) => city.id === parseInt(formData.city, 10)
      );
      const vehicle_types = setupData.vehicle_types.find(
        (vehicleType) => vehicleType.id === parseInt(formData.vehicle_types, 10)
      );
      const services = setupData.services.find(
        (service) => service.id === parseInt(formData.services, 10)
      );
      console.log("CITY ", city_);
      // Convert the appointment datetime to the appropriate format
      const formattedDatetime = new Date(
        formData.appointment_datetime
      ).toISOString();
      // Prepare the data to match the serializer structure
      const postData = {
        //...formData,
        appointment_datetime: formattedDatetime,
        contact_info: {
          full_name: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          address: formData.address,
          state: formData.state ? { id: state_.id, name: state_.name } : null,
          city: formData.city
            ? {
                id: city_.id,
                name: city_.name,
                state: { id: state_.id, name: state_.name },
              }
            : null,
        },
        vehicle_types: formData.vehicle_types
          ? [
              {
                id: vehicle_types.id,
                name: vehicle_types.name,
              },
            ]
          : null,
        services: formData.services
          ? { id: services.id, name: services.name }
          : null,
        additional_info: formData.additional_info,
      };
      // Make the API call to submit the data
      await submitAppointment({
        appointmentData: postData,
        setMessage,
        setLoading,
      });
      // Handle success or navigate to another page
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error gracefully
    }
  };

  const handleStateChange = async (stateId) => {
    try {
      const { cities } = await loadCities({ stateId, setMessage, setLoading });
      // const stateId = e.target.value;
      // const cities = setupData.states.find((state) => state.id === parseInt(stateId, 10))?.cities || [];
      setFormData((prevState) => ({
        ...prevState,
        state: stateId,
        city: "",
      }));
      setSetupData((prevState) => ({
        ...prevState,
        cities,
      }));
    } catch (error) {
      console.error("Error fetching cities: ", error);
    }
  };

  useEffect(() => {
    const fetchSetupData = async () => {
      try {
        const { serviceTypes, vehicleTypes, states, helpMessages } =
          await loadSetup({ setMessage, setLoading });
        setSetupData((prevState) => ({
          ...prevState,
          services: serviceTypes,
          vehicle_types: vehicleTypes,
          states: states,
          helpMessages: helpMessages,
        }));
      } catch (error) {
        console.error("Error fetching setup data: ", error);
        // Handle error gracefully
      }
    };

    fetchSetupData();
  }, [setMessage, setLoading]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={5} className="pt-4">
          <h2 className="h2 text-primary">
            Whypemasters - Auto-care Appointment booking
          </h2>
          <br />
          <p className="text-start text-muted mb-4">
            {setupData?.helpMessages?.intro}
          </p>
          <Form onSubmit={handleSubmit} className="mb-4">
            <header className="text-start mt-4 mb-4 text-info ">
              Personal Information
            </header>
            <Form.Group controlId="fullName" className="text-start mb-3">
              <Form.Label>
                <FaUser /> Full Name
              </Form.Label>
              <br />
              <Form.Text className="text-muted">
                You can include any tile you wish to be address with
              </Form.Text>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="email" className="text-start mb-3">
              <Form.Label>
                <FaEnvelope /> Email (Optional)
              </Form.Label>
              <br />
              <Form.Text className="text-muted">
                We'll leave you a message incase we are unbale to reach you via
                the phone number you provide. We won't share with second parties
                and we won't spam.
              </Form.Text>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="phone" className="text-start mb-3">
              <Form.Label>
                <FaPhone /> Phone
              </Form.Label>
              <br />
              <Form.Text className="text-muted">
                Please provide a valid phone number. Cross check to confirm
                you've entered correctly
              </Form.Text>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="state" className="text-start mb-3">
              <Form.Label>
                <FaMap /> State
              </Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={formData.state}
                //onChange={handleChange}
                onChange={(e) => handleStateChange(e.target.value)}
              >
                <option value="">Select your state</option>
                {setupData?.states?.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="city" className="text-start mb-3">
              <Form.Label>
                <FaCity /> City
              </Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={formData.city}
                onChange={handleChange}
              >
                <option value="">Select your city</option>
                {setupData?.cities?.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="address" className="text-start mb-3">
              <Form.Label>
                <FaHome /> Address
              </Form.Label>
              <br />
              <Form.Text className="text-muted">
                Please your address should include clear descriptions and
                landmarks (bus stop, church, mosque, shopping mall, etc) that
                will make it easier for us to locate.
              </Form.Text>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
            <header className="text-start mt-4 mb-4 text-info ">
              Appointment Information
            </header>
            {/* <Form.Group controlId="appointment_datetime" className="text-start mb-3">
              <Form.Label>
                <FaCalendarAlt /> Appointment Date
              </Form.Label>
              <Form.Control
                type="date"
                name="appointment_datetime"
                value={formData.appointment_datetime}
                min={currentDate}
                onChange={handleChange}
              />
            </Form.Group> */}
            <Form.Group
              controlId="appointment_datetime"
              className="text-start mb-3"
            >
              <Form.Label>
                <FaCalendarAlt /> Appointment Date
              </Form.Label>
              <br />
              <Form.Text className="text-muted">
                Click and select date and time. All schedule should have a 45
                minutes gap to allow us time honor the appointments.
              </Form.Text>
              {/* <Datetime
                input={true}
                value={formData.appointment_datetime}
                onChange={handleDatetimeChange}
                // initialViewMode=""
                
              /> */}
              {/* <Datetime
                input={true}
                value={formData.appointment_datetime}
                onChange={handleDatetimeChange}
                isValidDate={(current) => {
                  // Include only current and future dates
                  return current.isSameOrAfter(currentDate, "day");
                }}
                isValidTime={(current) => {
                  // Check if the current time falls within any excluded time range
                  const currentTime = {
                    hour: current.hours(),
                    minute: current.minutes(),
                  };
                  return !excludedTimes.some(
                    ({ startHour, startMinute, endHour, endMinute }) =>
                      currentTime.hour >= startHour &&
                      currentTime.hour < endHour &&
                      (currentTime.hour !== startHour ||
                        currentTime.minute >= startMinute) &&
                      (currentTime.hour !== endHour ||
                        currentTime.minute <= endMinute)
                  );
                }}
              /> */}

              <Datetime
                input={true}
                value={formData.appointment_datetime}
                onChange={handleDatetimeChange}
                isValidDate={(current) => {
                  // Include only current and future dates
                  return current.isSameOrAfter(currentDate, "day");
                }}
                isValidTime={(current) => {
                  // Check if the current time is at least 45 minutes later than the current time
                  const currentTime = {
                    hour: current.hours(),
                    minute: current.minutes(),
                  };
                  const currentDateTime = new Date(
                    current.year(),
                    current.month(),
                    current.date(),
                    currentTime.hour,
                    currentTime.minute
                  );
                  return (
                    currentDateTime >= currentDate &&
                    !excludedTimes.some(
                      ({ startHour, startMinute, endHour, endMinute }) =>
                        currentTime.hour >= startHour &&
                        currentTime.hour < endHour &&
                        (currentTime.hour !== startHour ||
                          currentTime.minute >= startMinute) &&
                        (currentTime.hour !== endHour ||
                          currentTime.minute <= endMinute)
                    )
                  );
                }}
              />
            </Form.Group>

            <Form.Group controlId="vehicle_types" className="text-start mb-3">
              <Form.Label>
                <FaCar /> Vehicle Type
              </Form.Label>
              <Form.Control
                as="select"
                name="vehicle_types"
                value={formData.vehicle_types}
                onChange={handleChange}
              >
                <option value="">Select Vehicle Type</option>
                {setupData?.vehicle_types?.map((vehicle_types) => (
                  <option key={vehicle_types.id} value={vehicle_types.id}>
                    {vehicle_types.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="services" className="text-start mb-3">
              <Form.Label>
                <FaWrench /> Service Type
              </Form.Label>
              <Form.Control
                as="select"
                name="services"
                value={formData.services}
                onChange={handleChange}
              >
                <option value="">Select Service Type</option>
                {setupData?.services?.map((services) => (
                  <option key={services.id} value={services.id}>
                    {services.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="additional_info" className="text-start mb-3">
              <Form.Label>
                <FaInfoCircle /> Additional Information (Optional)
              </Form.Label>
              <br />
              <Form.Text className="text-muted">
                {setupData?.helpMessages?.additional_information_help_message}
              </Form.Text>
              <Form.Control
                as="textarea"
                rows={3}
                name="additional_info"
                value={formData.additional_info}
                onChange={handleChange}
                className="mb-4"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className={`btn btn-sm ${loading ? "disabled" : ""}`}
              disabled={loading}
            >
              Confirm and Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AppointmentForm;
