// Filename: src/api/main.js
import axios from "axios";
import { mainApi } from "./config";

const formatErrorMessage = (errors) => {
  if (errors) {
    return Object.entries(errors).map(([field, errorMessages]) => (
      <div key={field}>
        <strong>{field}:</strong> {errorMessages?.join(", ")}
      </div>
    ));
  }
};

const getFormattedErrorMessages = (error) => {
  // Format error messages received from the server
  const formattedErrors = Object.entries(error?.response?.data).map(
    ([field, errors]) => `${field}: ${errors.join(", ")}`
  );
  return formattedErrors;
};

const loadSetup = async ({ setMessage, setLoading }) => {
  try {
    setLoading(true); // Set loading to true before making the API request
    const response = await mainApi.get("/setup/");
    console.log(response.data);
    //setMessage({ title: "Success", text: "Setup data loaded successfully", type: "success" });
    return response.data;
  } catch (error) {
    if (error?.code === "ERR_NETWORK") {
      setMessage({
        title: "Network Error",
        text: `${error?.message}, please check internet connection.`,
        type: "danger",
      });
    } else {
      setMessage({
        title: "API Error",
        text:
          error?.message ||
          "An error occurred while fetching data from the server. Please try again later.",
        type: "danger",
      });
    }
    throw error;
  } finally {
    setLoading(false);
  }
};

export const loadCities = async ({ stateId, setMessage, setLoading }) => {
  try {
    setLoading(true);
    const response = await mainApi.get(`/setup/?load-cities=${stateId}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error?.code === "ERR_NETWORK") {
      setMessage({
        title: "Network Error",
        text: `${error?.message}, please check internet connection.`,
        type: "danger",
      });
    } else {
      setMessage({
        title: "API Error",
        text:
          error?.message ||
          "An error occurred while fetching data from the server. Please try again later.",
        type: "danger",
      });
    }
  } finally {
    setLoading(false);
  }
};

export const submitAppointment = async ({
  appointmentData,
  setLoading,
  setMessage,
}) => {
  try {
    setLoading(true);
    const response = await mainApi.post("/create/", appointmentData);
    console.log(response.data);
    setMessage({
      title: "Success",
      text: "Appointment created successfully",
      type: "success",
    });
  } catch (error) {
    if (error?.code === "ERR_NETWORK") {
      setMessage({
        title: "Network Error",
        text: `${error?.message}, please check internet connection.`,
        type: "danger",
      });
    } else if (error?.response && error?.response?.status === 400) {
      const formattedErrors = getFormattedErrorMessages(error);
      setMessage({
        title: "Validation Error",
        text: formattedErrors.join("; "),
        type: "danger",
      });
    } else {
      setMessage({
        title: "API Error",
        text: "An error occurred while submitting the appointment. Please try again later.",
        type: "danger",
      });
    }
    console.log(error?.response?.data);
  } finally {
    setLoading(false);
  }
};

export default loadSetup;
