import React from "react";
import { Container, Row, Modal, Alert } from "react-bootstrap";
import BreathingImage from "./BreathingImage";

export const ActivityContext = React.createContext();
export const ActivityWrapper = ({ children }) => {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  // useEffect(() => {
  //   console.log("Loading state", loading);
  // }, [loading]);

  const handleClose = () => {
    setMessage("");
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [message]);

  return (
    <ActivityContext.Provider
      value={{ loading, setLoading, message, setMessage }}
    >
      <Modal show={loading} centered>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          {/* <Spinner animation="border" variant="primary" /> */}
          <div className="ml-3">
            <BreathingImage source={require("../assets/images/logo.png")} />

            <p className="m-0 ml-3 " aria-label="Loading">
              Please wait...
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Container>
        <Row className="justify-content-center mt-3">
          <Alert
            variant={message.type || "danger"}
            show={message?.text?.length > 0}
            onClose={handleClose}
            dismissible
            className="col-md-5 fade text-start mt-3 fixed-top"
          >
            <Alert.Heading>{message.title}</Alert.Heading>
            {/* <p>{message.text}</p> */}
            <div>{message.text}</div>
          </Alert>
        </Row>
      </Container>

      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = () => React.useContext(ActivityContext);
