import React, { useEffect, useState } from "react";

const BreathingImage = ({ source, style }) => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const breatheAnimation = () => {
      const growInterval = setInterval(() => {
        setScale(1.2);
        setTimeout(() => {
          setScale(1);
        }, 1000);
      }, 2000); // 1000ms to grow, 1000ms to shrink back to normal
      return () => clearInterval(growInterval);
    };

    breatheAnimation();
  }, []);

  return (
    <div style={{ textAlign: "center" }} className="pt-4">
      <img
        src={source}
        style={{ width: 120, height: 120, transform: `scale(${scale})` }}
        alt="Whypemasters logo"
        about="Whypemasters logo"
      />
    </div>
  );
};

export default BreathingImage;
