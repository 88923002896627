import "./App.css";
import AppointmentForm from "./components/AppointmentForm";
import BreathingImage from "./components/BreathingImage";
import { ActivityWrapper } from "./components/ActivityWrapper";

function App() {
  return (
    <div className="App">
      <ActivityWrapper>
        <BreathingImage source={require("./assets/images/logo.png")} />
        <AppointmentForm />
      </ActivityWrapper>
    </div>
  );
}

export default App;
