import axios from "axios";

const API_URL = "https://api.whypemasters.ng/booking";

const axiosConfig = {
  timeout: 30000,
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const axiosInstance = axios.create(axiosConfig);

// Define a function to retry failed requests
const retryFailedRequest = (error) => {
  const { config } = error;

  // If config does not exist or the retry option is not set, reject the promise
  if (!config || !config.retry) return Promise.reject(error);

  // Set the variable to keep track of the retry count
  config.retryCount = config.retryCount || 0;

  // Check if the maximum number of retries has been reached
  if (config.retryCount >= config.retry) {
    // Reject with the error message
    return Promise.reject(error);
  }

  // Increment the retry count
  config.retryCount += 1;

  // Create a new promise to retry the request after the delay
  const delay = new Promise((resolve) => {
    setTimeout(() => resolve(), config.retryDelay || 0);
  });

  // Return the promise to retry the request
  return delay.then(() => axiosInstance(config));
};

// Add an interceptor to handle failed requests
axiosInstance.interceptors.response.use(undefined, (error) => {
  // Check if the error response status code indicates a network error
  const isNetworkError = error.code === "ECONNABORTED" || !error.response;

  // If it's a network error or the request should be retried, retry the request
  if (isNetworkError || (error.config && error.config.retry)) {
    return retryFailedRequest(error);
  }

  // Return the error if it's not a network error or doesn't need to be retried
  return Promise.reject(error);
});

// Create a custom interceptor to handle retries with delay
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    if (response) {
      // Request was made and server responded with a status code
      if (response.status === 429) {
        // Retry if response status is 429 (Too Many Requests)
        return new Promise((resolve) => {
          setTimeout(() => resolve(axiosInstance(config)), 2000); // Retry after 2 seconds
        });
      }
    }
    return Promise.reject(error);
  }
);

export { API_URL, axiosConfig, axiosInstance as mainApi };
